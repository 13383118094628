
@font-face {
    font-family: 'CustomFont';
    /* src: url("./MTNBrighterSans-Regular.ttf") format("ttf"), url("./MTNBrighterSans-Bold.ttf") format("ttf") */
    src: url("./MTNBrighterSans-Regular.ttf") format("truetype");
}

body {
    /* margin: 0; */
    font-family: 'CustomFont';
    /* font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}